<template>
  <div class="page-rule">
    <div class="rule-tit">
      预约须知
    </div>
    <div class="ruel_content">
      <p>当今各行各业的信息化、智能化建设越来越广泛，整个社会对各个行业的办事效率的要求越来越高。尤其是服务性行业，既要满足被服务人的服务需求，又要提高服务质量，提高服务效率，例如医院门诊等。现在的患者不仅仅要求医院等满足业务上的需要，还要求医院尽量减少患者的等待时间，而医院本身由于竞争的需要，也要求提高本身的办事效率，提高本身服务的形象。而这些窗口服务的排队现象在所难免，为了在排队时减少办事人的等候时间，为患者看病创造一个良好的环境，排队管理系统应运而生。</p>
      <p>当今各行各业的信息化、智能化建设越来越广泛，整个社会对各个行业的办事效率的要求越来越高。尤其是服务性行业，既要满足被服务人的服务需求，又要提高服务质量，提高服务效率，例如医院门诊等。现在的患者不仅仅要求医院等满足业务上的需要，还要求医院尽量减少患者的等待时间，而医院本身由于竞争的需要，也要求提高本身的办事效率，提高本身服务的形象。而这些窗口服务的排队现象在所难免，为了在排队时减少办事人的等候时间，为患者看病创造一个良好的环境，排队管理系统应运而生。</p>
      <p>当今各行各业的信息化、智能化建设越来越广泛，整个社会对各个行业的办事效率的要求越来越高。尤其是服务性行业，既要满足被服务人的服务需求，又要提高服务质量，提高服务效率，例如医院门诊等。现在的患者不仅仅要求医院等满足业务上的需要，还要求医院尽量减少患者的等待时间，而医院本身由于竞争的需要，也要求提高本身的办事效率，提高本身服务的形象。而这些窗口服务的排队现象在所难免，为了在排队时减少办事人的等候时间，为患者看病创造一个良好的环境，排队管理系统应运而生。</p>
    </div>
    <div class="btn_box">
      <van-field name="radio" label="">
        <template #input>
          <van-radio-group v-model="radio" direction="horizontal">
            <van-radio name="1">我已阅读并同意预约须知</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-button type="primary" block color="#106FFF" class="btn" @click="onsubmit">确认预约</van-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Button, Empty, Col, Row, Dialog, Field, RadioGroup, Radio, Toast } from 'vant'

export default {
  name: 'HomeIndex',
  components: {
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Col.name]: Col,
    [Row.name]: Row,
    [Dialog.name]: Dialog,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Toast.name]: Toast
  },
  data() {
    return {
      logo: require('../../assets/logo.png'),
      radio: ''
    }
  },
  computed: {
    avatar() {
      return this.userInfo ? this.userInfo.avatar : this.logo
    },
    introduction() {
      return this.userInfo ? this.userInfo.introduction : '南京蓝牛软件有限公司'
    },
    ...mapGetters([
      'userInfo'
    ])
  },
  methods: {
    onsubmit() {
      if (this.radio === '') {
        Toast.fail({
          message: '请勾选预约须知',
          forbidClick: true
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .page-rule{width: 100%;float: left;}
  .rule-tit{
    width: 90%;margin: 15px 5%;
    float: left;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    color: #101112;
  }
  .ruel_content{
    width: 90%;
    margin:5px 5%;
    float: left;
    font-size: 15px;
    line-height: 26px;
    color: #101112;
    height: calc(100vh - 240px);
    overflow-y: scroll;
  }
  .ruel_content p{margin: 5px 0;text-indent: 20px;}
  .btn_box{
    width: 90%;
    margin:5px 5%;
    float: left;
    height: 170px;
  }
  /deep/ .van-cell::after{border: 0px;}
  .btn{margin-top: 20px;}
  /deep/ .van-radio__label{color: #8993A8;font-size: 15px;font-weight: 400}
</style>
